.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Poppins", sans-serif;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Chat Message Card Action hover render */
.chat-menu-button {
  transition: all 100ms ease-in;
  visibility: hidden;
  opacity: 0;
}

.chat-menu:hover .chat-menu-button {
  visibility: visible;
  opacity: 1;
}

.reply-icon {
  transition: all 100ms ease-in;
  visibility: hidden;
  opacity: 0;
}

.replay-icon-parent:hover .reply-icon {
  visibility: visible;
  opacity: 1;
}
/* Chat Message Card Action hover render */
@keyframes parentMessageBackground {
  from {
    background-color: #e2e8f0;
  }
}

.show-parent-id {
  /* background-color: #e2e8f0; */
  transition: all;
  animation: parentMessageBackground 2s ease-in-out;
}

.text-focus-none:focus {
  outline: none;
}
