@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-light: #196674;
  --primary-main: #0c4349;
  --primary-dark: #041618;
  --secondary-main: #ff7;
  --divider: #d1d5db;
  --hover: #f9fafb;
  --surface-light: #f9fafb;
  --surface: #f3f4f6;
  --surface-dark: #e5e7eb;
  --neutral: #6b7280;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar-thumb {
  /* width: 5px!important; */
  background-color: #a0a0a0 !important;
  border-radius: 10px !important;
}

::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
  margin-top: 10px !important;
  padding-top: 5px !important;
}

.prose {
  color: inherit;
  max-width: inherit;
  min-width: inherit;
  min-height: 20px;
}

.prose :where(li):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin: 0;
}
.prose :where(p):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
  margin: 0;
}
.prose h1{
  color: inherit !important;
  margin: 0 !important;
}
.prose h2{
  color: inherit !important;
  margin: 0 !important;
}
.prose h3{
  color: inherit !important;
  margin: 0 !important;
}
.prose h4{
  color: inherit !important;
  margin: 0 !important;
}
.prose strong{
  color: inherit !important;
}
.prose blockquote{
  color: inherit !important;
}
.prose img{
margin: 0 !important;
}